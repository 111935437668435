import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Display,
  Column,
  Panel,
  Button,
  Card,
  Line,
} from "ktm-ui";
import Expense_typesScreen from "./Expense_types";
import Edit_hsa_limitsScreen from "./Edit_hsa_limits";
import Edit_hsa_settingsScreen from "./Edit_hsa_settings";
import Edit_lsa_limitsScreen from "./Edit_lsa_limits";
import Edit_lsa_settingsScreen from "./Edit_lsa_settings";
import Edit_flex_limitsScreen from "./Edit_flex_limits";
import Edit_flex_settingsScreen from "./Edit_flex_settings";
import Remove_hsaScreen from "./Remove_hsa";
import Remove_flexScreen from "./Remove_flex";
import Remove_lsaScreen from "./Remove_lsa";
import Add_lsaScreen from "./Add_lsa";
import Add_hsaScreen from "./Add_hsa";
import Add_flexScreen from "./Add_flex";
import Change_credit_calcScreen from "./Change_credit_calc";
import CustomToolTip from "components/CustomToolTip";
import CustomCategoryPlanAdminScreen from "./CustomCategoryPlanAdminScreen";
import axios from "axios";
import "../../styles/Ops.css";

interface IdsBenCat {
  Id: string;
  Policy_Id: string;
  FlexSingleLimit: number;
  FlexCoupleLimit: number;
  FlexFamilyLimit: number;
  Benefit: string;
  HSASingleLimit: number;
  HSACoupleLimit: number;
  HSAFamilyLimit: number;
  HSASinglePercent: number;
  HSACouplePercent: number;
  HSAFamilyPercent: number;
  CreditCalcMethod: string;
  HSALabel: string;
  HasProrating: boolean;
  LSASingleLimit: number;
  LSACoupleLimit: number;
  LSAFamilyLimit: number;
  LSASinglePercent: number;
  LSACouplePercent: number;
  LSAFamilyPercent: number;
  LSALabel: string;
  AllowLSADependentClaims: boolean;
  FlexSinglePercent: number;
  FlexCouplePercent: number;
  FlexFamilyPercent: number;
  AllowRSPOption: boolean;
  AllowStockOption: boolean;
  HasHSA: boolean;
  HasLSA: boolean;
  RSPLabel: string;
  Stocklabel: string;
  DefaultRspAllocation: number;
  DefaultStockAllocation: number;
  DefaultHSAAllocation: number;
  DefaultLSAAllocation: number;
  IsFlex: boolean;
  IsNonParticipating: boolean;
  BenfitCategory: string;
  HSACap: number;
  LSACap: number;
  RSPCap: number;
  StockCap: number;
  Category_Id: string;
}

export default KTMScreen(
  { name: "planadmin.ben-limit.record" },
  class extends React.Component<{ policy_id: string; limit_id: string }, any> {
    data: {
      dsBenCat: IdsBenCat;
    };

    constructor(props) {
      super(props);
      this.state = {
          isAllowBenefitCategoryEdit: false,
          employeesCount: 0,
          employeeHasClaims: false,
      };
    }

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenCat";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
        "@limit_id": this.props.limit_id,
      });
      screen.title = this.data.dsBenCat.Benefit;
      await screen.triggerAction(this.rule_calc_method);
      await screen.triggerAction(this.rule_convert_tools);
      await screen.triggerAction(this.rule_add_toolbar);
      await screen.triggerAction(this.rule_account_sections);
      await this.checkIsAllowBenefitCategoryEdit();
      await this.getNumberofEmployees();
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader entityLabel="Benefit" label={this.data.dsBenCat.Benefit}>
          <Line>
            {/* <Display field={field("BenfitCategory")} /> */}
            <div className="RT-Static-Field">
              <label className="RT-Static-Field__label">
                Enrolled Employees 
              </label>
              <div className="RT-Static-Field__content">
                {this.state.employeesCount}
              </div>
            </div>
          </Line>
        </RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="Details" template={this.main_tab} />
          <Tab label="Additional Accounts" template={this.custom_categories} />
        </TabSet>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.hsa)}
            {draw(this.lsa)}
            {draw(this.flex)}
            {draw(this.expense_type_list)}
            {draw(this.editRequests)}
          </Column>
        </Panel>
      );
    }

    expense_type_list({ field, draw, action }: RenderEvent) {
      return (
        <Expense_typesScreen
          policy_id={this.props.policy_id}
          limit_id={this.data.dsBenCat.Id}
          bencat_name={this.data.dsBenCat.BenfitCategory}
        />
      );
    }
 
    custom_categories({ field, draw, action}: RenderEvent) {
      return (
        <CustomCategoryPlanAdminScreen
          policy_id={this.props.policy_id}
          limit_id={this.data.dsBenCat.Id}
          category_id={this.data.dsBenCat.Category_Id}
          is_flex={this.data.dsBenCat.IsFlex}
        />
      );
    }

    hsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.hsa_limits)}</Column>
        </Panel>
      );
    }

    lsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.lsa_limits)}</Column>
        </Panel>
      );
    }

    flex({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.flex_limits)}</Column>
          <Column>{draw(this.flex_settings)}</Column>
        </Panel>
      );
    }

    credit_calculation({ field, draw, action }: RenderEvent) {
      return (
        <Card label="Credit calculation">
          <Display field={field("CreditCalcMethod")} />
        </Card>
      );
    }

    hsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="HSA Limits"
          actions={
            <>
              {this.state.isAllowBenefitCategoryEdit && 
                <Button
                  action={action(this.edit_hsa_limits)}
                  label="Edit"
                  style="link"
                />
              }
            </>
          }
        >
          {draw(this.hsa_amount)}
          {draw(this.hsa_percent)}
        </Card>
      );
    }

    hsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card label="HSA options">
          <Display field={field("HSALabel")} />
        </Card>
      );
    }

    lsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="LSA Limits"
          actions={
            <>
              {this.state.isAllowBenefitCategoryEdit && 
                <Button
                  action={action(this.edit_lsa_limits)}
                  label="Edit"
                  style="link"
                />
              }
            </>
          }
        >
          {draw(this.lsa_amount)}
          {draw(this.lsa_percent)}
          <Display 
            field={field("AllowLSADependentClaims")}
            toolTip={<CustomToolTip
              header="Allow LSA Dependent Claims"
              bodyContent="This option determines if employees can reimburse LSA expenses for their dependents or if the LSA is an Employee Only benefit."
            />}
          />
        </Card>
      );
    }

    editRequests({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Request Edits"
        >
          {draw(this.edit_request)}
        </Card>
      );
    }

    edit_request({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <span>To make more comprehensive edits to this category beyond adjusting the category limits, email <a style={{"color":"#0d6efd"}} href="mailto:support@nationalhealthclaim.com">support@nationalhealthclaim.com</a> with your request.</span>
        </Line>
      );
    }


    lsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="LSA options"
        >
          <Line>
            <Display field={field("LSALabel")} />
            <Display field={field("AllowLSADependentClaims")} />
          </Line>
        </Card>
      );
    }

    flex_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card 
          label="Flex settings">
          <Line>
            <Display field={field("HasHSA")} />
            <Display field={field("DefaultHSAAllocation")} />
          </Line>
          <Line>
            <Display field={field("HSALabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("HSACap")} />
            ) : <></>}
          </Line>
          <Line>
            <Display field={field("HasLSA")} />
            <Display field={field("DefaultLSAAllocation")} />
          </Line>
          <Line>
            <Display field={field("LSALabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("LSACap")} />
            ) : <></>}
          </Line>
          <Line>
            <Display
                field={field("AllowLSADependentClaims")}
                toolTip={
                  <CustomToolTip
                    header="Allow LSA Dependent Claims"
                    bodyContent="This option determines if employees can reimburse LSA expenses for their dependents or if the LSA is an Employee Only benefit."
                  />
                }
            />
          </Line>
          <Line>
            <Display field={field("AllowRSPOption")} />
            <Display field={field("DefaultRspAllocation")} />
          </Line>
          <Line>
            <Display field={field("RSPLabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("RSPCap")} />
            ) : <></>}
          </Line>
          {/* <Line>
            <Display field={field("AllowStockOption")} />
            <Display field={field("DefaultStockAllocation")} />
            <Display field={field("Stocklabel")} />
          </Line> */}
        </Card>
      );
    }

    flex_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Flex Limits"
          actions={
            <>
              {this.state.isAllowBenefitCategoryEdit && 
                <Button
                  action={action(this.edit_flex_limits)}
                  label="Edit"
                  style="link"
                />
              }
            </>
          }
        >
          {draw(this.flex_amount)}
          {draw(this.flex_percent)}
        </Card>
      );
    }

    hsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("HSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("HSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("HSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    hsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("HSASinglePercent")} />
          <Display field={field("HSACouplePercent")} />
          <Display field={field("HSAFamilyPercent")} />
        </Line>
      );
    }

    lsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("LSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("LSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("LSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    lsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("LSASinglePercent")} />
          <Display field={field("LSACouplePercent")} />
          <Display field={field("LSAFamilyPercent")} />
        </Line>
      );
    }

    flex_amount({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("FlexSingleLimit")} />
            <Display field={field("FlexCoupleLimit")} />
            <Display field={field("FlexFamilyLimit")} />
          </Line>
        </>
      );
    }

    flex_percent({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("FlexSinglePercent")} />
            <Display field={field("FlexCouplePercent")} />
            <Display field={field("FlexFamilyPercent")} />
          </Line>
        </>
      );
    }

    add_action_bar({ field, draw, action }: RenderEvent) {
      return (
        <div className="RT-Action-Bar">
          <Button action={action(this.add_hsa)} label="Add HSA" style="add" />
          <Button action={action(this.add_lsa)} label="Add LSA" style="add" />
          <Button action={action(this.add_flex)} label="Add Flex" style="add" />
        </div>
      );
    }

    convert_action_bar({ field, draw, action }: RenderEvent) {
      return (
        <div className="RT-Action-Bar">
          <Button
            action={action(this.convert_to_flex)}
            label="Convert to flex"
            style="link"
          />
          <Button
            action={action(this.convert_to_fixed)}
            label="Convert to fixed"
            style="link"
          />
        </div>
      );
    }

    async edit_hsa_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsa_limitsScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount}
          employeeHasClaims={this.state.employeeHasClaims}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_hsa_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsa_settingsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_lsa_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsa_limitsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
          employeeHasClaims={this.state.employeeHasClaims}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_lsa_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsa_settingsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flex_limitsScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount}
          employeeHasClaims={this.state.employeeHasClaims}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flex_settingsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_hsaScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_flexScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_lsaScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_lsaScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_hsaScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_flexScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async convert_to_flex({ screen }: ActionEvent) {}

    async convert_to_fixed({ screen }: ActionEvent) {}

    async change_credit_calc({ screen }: ActionEvent) {
      await screen.openDialog(
        <Change_credit_calcScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

    async rule_calc_method({ screen }: ActionEvent) {
      screen.hide(this.hsa_amount);
      screen.hide(this.hsa_percent);
      screen.hide(this.lsa_amount);
      screen.hide(this.lsa_percent);
      screen.hide(this.flex_amount);
      screen.hide(this.flex_percent);
      if (screen.getValue("@dsBenCat.CreditCalcMethod") == "SALARY") {
        screen.unhide(this.hsa_percent);
        screen.unhide(this.lsa_percent);
        screen.unhide(this.flex_percent);
      } else {
        screen.unhide(this.hsa_amount);
        screen.unhide(this.lsa_amount);
        screen.unhide(this.flex_amount);
      }
    }

    async rule_convert_tools({ screen }: ActionEvent) {
      screen.hide(this.convert_to_flex);
      screen.hide(this.convert_to_fixed);
      if (screen.getValue("@dsBenCat.IsFlex")) {
        screen.unhide(this.convert_to_fixed);
      } else if (
        screen.getValue("@dsBenCat.HasHSA") ||
        screen.getValue("@dsBenCat.HasLSA")
      ) {
        screen.unhide(this.convert_to_flex);
      }
    }

    async rule_add_toolbar({ screen }: ActionEvent) {
      screen.hide(this.add_lsa);
      screen.hide(this.add_hsa);
      screen.hide(this.add_flex);
      if (screen.getValue("@dsBenCat.PolicyHasFlex") && !screen.getValue("@dsBenCat.IsFlex")) {
        screen.unhide(this.add_flex);
      }
    
      if (!screen.getValue("@dsBenCat.PolicyHasFlex")){
        if(screen.getValue("@dsBenCat.PolicyHasHsa") && !screen.getValue("@dsBenCat.HasHSA")){
          screen.unhide(this.add_hsa);
        }
        if(screen.getValue("@dsBenCat.PolicyHasLsa") && !screen.getValue("@dsBenCat.HasLSA")){
          screen.unhide(this.add_lsa);
        }
      }
    }

    async rule_account_sections({ screen }: ActionEvent) {
      screen.hide(this.hsa);
      screen.hide(this.lsa);
      screen.hide(this.flex);
      if (screen.getValue("@dsBenCat.IsFlex")) {
        screen.unhide(this.flex);
      } else {
        if (screen.getValue("@dsBenCat.HasHSA")) {
          screen.unhide(this.hsa);
        }
        if (screen.getValue("@dsBenCat.HasLSA")) {
          screen.unhide(this.lsa);
        }
      }
    }

    checkIsAllowBenefitCategoryEdit = async () => {
      var self = this;
      await axios
        .get(
          "/api/PlanAdmin/isallowbenefitcategoryedit?policyId=" + this.data.dsBenCat.Policy_Id,
        )
        .then((response) => {
          self.setState({
            isAllowBenefitCategoryEdit: response.data.isAllowBenefitCategoryEdit,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    getNumberofEmployees = async () => {
      try {
          axios.get("/api/BenefitCategories/getnumberofemployees", {
              params: { 
                  benefitCategoryId: this.data.dsBenCat.Category_Id 
              }
          }).then((response) => {
              if (response.data.success) {
                  this.setState({
                    employeesCount: response.data.employeesCount,
                    employeeHasClaims: response.data.employeeHasClaims
                  });
              }
          });
      } catch (error) {
          console.error(error);
      }
    }
  }
);
