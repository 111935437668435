import React, { Component } from "react";
import { FiEdit, FiUsers } from "react-icons/fi";
import { BiBuildings, BiBarChartSquare } from "react-icons/bi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { HiOutlineSearchCircle } from "react-icons/hi";
import { Column, Panel } from "ktm-ui";
import { CustomLoading } from "components/CustomLoading";
import "bootstrap/dist/css/bootstrap.min.css";
import toastr from "toastr";
import moment from "moment";
import Moment from "react-moment";
import axios from "axios";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Display,
  Button,
  Card,
} from "ktm-ui";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { Col, Spinner } from "react-bootstrap";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import OpsFinancialsReport from "./Reports/OpsFinancialsReport";
import CustomPagination from "../components/CustomPagination";

export default KTMScreen(
  { name: "dashboardreport" },
  class extends React.Component<any, any> {
    state = {
      loadingPolicySummary: true,
      loadingPolicyReports: true,
      loadingDaily: true,
      loadingClaims: true,
      loadingAdvisorReports: true,
      reportDaily: null,
      reportClaims: null,
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      currentTab: "adjudication",
      reportPolicySummary: null,
      reportPolicyReports: null,
      reportAdvisors: null,
        currentPageDistroAdvisors: 1,
        currentPageAdvisorPolicy: 1,
        distroAdvisorItemsPerPage: 10,
        advisorPolicyItemsPerPage: 10,
        sortColumn: null,
        sortDirection: null,
        distroAdvisorsList: [],
        advisorPolicyList: [],
        enrollmentDropdown: "ALL", 
        enrollmentFromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        enrollmentToDate: new Date(),
        claimsAdjudicated: false,
    };

    componentDidMount() {
      this.initializeDaily();
      this.initializeClaims();
      this.initializePolicySummary();
      this.initializePolicyReports();
      this.initializeAdvisorReports();
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.fromDate !== this.state.fromDate ||
        prevState.toDate !== this.state.toDate ||
        prevState.claimsAdjudicated !== this.state.claimsAdjudicated
      ) {
        this.setState({ loadingClaims: true });
        this.initializeClaims();
      }

        if ( prevState.enrollmentDropdown !== this.state.enrollmentDropdown ||
            prevState.enrollmentFromDate !== this.state.enrollmentFromDate ||
            prevState.enrollmentToDate !== this.state.enrollmentToDate
        ) {
            this.setState({ loadingPolicySummary: true });
            this.initializePolicySummary();
        }
    }

    setFromDate = (date) => {
      this.setState({
        fromDate: date,
      });
    };

    setToDate = (date) => {
      this.setState({
        toDate: date,
      });
    };
    
    changeEnrollmentDropdown = (value) => {
      this.setState( { enrollmentDropdown: value } )
      this.initializePolicySummary();
    }
    
    setEnrollmentFromDate = (date) => {
      this.setState({
          enrollmentFromDate: date,
      });
    };
    
    setEnrollmentToDate = (date) => {
      this.setState({
          enrollmentToDate: date,
      });
    };

    initializeDaily = () => {
      fetch("/api/opsreport/getadjudicationdaily", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loadingDaily: false,
            reportDaily: data.reportDaily,
          });
        })
        .catch((error) => {
          this.setState({ loadingDaily: false });
        });
    };

    initializeClaims = () => {
      fetch(
        "/api/opsreport/getadjudicationclaims?dateFrom=" +
          this.state.fromDate.toISOString() +
          "&dateTo=" +
          this.state.toDate.toISOString(),
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState(
            {
              loadingClaims: false,
              reportClaims: data.reportClaims,
            },
          );
        })
        .catch((error) => {
          this.setState({ loadingClaims: false });
        });
    };

    initializePolicySummary = () => {
        var fetch_api = "/api/opsreport/getpolicysummary";
        if (this.state.enrollmentDropdown !== "ALL") {
            fetch_api += "?dateFrom=" + this.state.enrollmentFromDate.toISOString() + 
                         "&dateTo=" + this.state.enrollmentToDate.toISOString();
        }
        fetch(fetch_api, {
        method: "GET",
        })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loadingPolicySummary: false,
            reportPolicySummary: data.policySummary,
          });
        })
        .catch((error) => {
          this.setState({ loadingPolicySummary: false });
        });
    };
    
    initializePolicyReports = () => {
      fetch("/api/opsreport/getpolicyreports", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loadingPolicyReports: false,
            reportPolicyReports: data.policyReports,
          });
        })
        .catch((error) => {
          this.setState({ loadingPolicyReports: false });
        });
    };
    initializeAdvisorReports = () => {
      fetch("/api/opsreport/getadvisorreports", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            reportAdvisors: data.advisorReport,
            loadingAdvisorReports: false,
            distroAdvisorsList: data.advisorReport.distroAdvisorCount,
            advisorPolicyList: data.advisorReport.advisorPolicyCount,
          });
        })
        .catch((error) => {
          this.setState({ loadingAdvisorReports: false });
        });
    };

    setTab = (val) => {
      this.setState({ currentTab: val });
    };
    
    currentDistroAdvisorRecords = () => {
      const indexOfLastRecord = this.state.currentPageDistroAdvisors * this.state.distroAdvisorItemsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - this.state.distroAdvisorItemsPerPage;
      return this.state.distroAdvisorsList.slice(indexOfFirstRecord, indexOfLastRecord);
    };

    currentAdvisorPolicyRecords = () => {
      const indexOfLastRecord = this.state.currentPageAdvisorPolicy * this.state.advisorPolicyItemsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - this.state.advisorPolicyItemsPerPage;
      return this.state.advisorPolicyList.slice(indexOfFirstRecord, indexOfLastRecord);
    };
    
    nPages = (recordCountLength, itemsPerPage) => {
        return Math.ceil(recordCountLength / itemsPerPage);
    }

    setDistroAdvisorCurrentPage = (val) => {
        this.setState( { currentPageDistroAdvisors: val } )
    }

    setAdvisorPolicyCurrentPage = (val) => {
      this.setState( { currentPageAdvisorPolicy: val } )
    }

    handleSort = (column, recordList, cat) => {
      const { sortColumn, sortDirection } = this.state;
      const direction =
          sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
      const sortedData = [...recordList].sort((a, b) => {
          if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
          if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
          return 0;
      });

        this.setState({
            sortColumn: column,
            sortDirection: direction,
        });
      if (cat == "distro") {
          this.setState({ distroAdvisorsList: sortedData });
      } else if (cat == "policy") {
          this.setState({ advisorPolicyList: sortedData });
      }
    };
    
    render() {
      let accountTypeChart = [];
      let zeroPaidChart = [];
      let adjudicationTotalsChart = [];
      if (this.state.reportClaims != null) {
        accountTypeChart = [
          { name: "HSA", value: this.state.reportClaims.totalPaidHsa },
          { name: "LSA", value: this.state.reportClaims.totalPaidLsa },
        ];
        zeroPaidChart = [
          { name: "Paid", value: this.state.reportClaims.paidCount },
          { name: "$0 Paid", value: this.state.reportClaims.paidZeroCount },
        ];
        adjudicationTotalsChart = this.state.reportClaims.adjudicationTotals;
      }

      const pieColors = ["#223e7f", "#059454"];
      const zeroPaidColors = ["#059454", "#838383"];

      const accountTypeRadian = Math.PI / 180;
      const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
      }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * accountTypeRadian);
        const y = cy + radius * Math.sin(-midAngle * accountTypeRadian);

        let name = "HSA";
        if (index == 1) {
          name = "LSA";
        }

        return (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {name + ` ${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };
      const renderZeroPaidLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
      }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * accountTypeRadian);
        const y = cy + radius * Math.sin(-midAngle * accountTypeRadian);

        let name = "Paid";
        if (index == 1) {
          name = "$0 Paid";
        }

        return (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {name + ` ${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };

      let distributionData = null;
      if (this.state.reportPolicyReports != null) {
        distributionData = [
          {
            name: "1 Employee",
            Companies:
              this.state.reportPolicyReports.employeeDistribution.batch1,
          },
          {
            name: "2-10 Employees",
            Companies:
              this.state.reportPolicyReports.employeeDistribution.batch2,
          },
          {
            name: "11-50 Employees",
            Companies:
              this.state.reportPolicyReports.employeeDistribution.batch3,
          },
          {
            name: "50+ Employees",
            Companies:
              this.state.reportPolicyReports.employeeDistribution.batch4,
          },
        ];
      }

      let provinceTable = null;
      let provincePie = null;
      if (
        this.state.reportPolicyReports != null &&
        this.state.reportPolicyReports.provinceCount.length > 0
      ) {
        const COLORS = [
          "#8884d8",
          "#82ca9d",
          "#FFBB28",
          "#FF8042",
          "#AF19FF",
          "#FF5733",
          "#33FF57",
          "#5733FF",
          "#FF3399",
          "#33FF99",
        ];
        const provinceData = this.state.reportPolicyReports.provinceCount.map(
          (item, index) => ({
            name: item.province,
            value: item.count,
            label: item.province,
            color: COLORS[index % COLORS.length],
          })
        );

        provincePie = (
          <div className="chart-container">
            <PieChart width={400} height={400}>
              <Pie
                data={provinceData}
                color="#000000"
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                labelLine={true}
                outerRadius={150}
                fill="#8884d8"
              >
                {provinceData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend layout="horizontal" verticalAlign="bottom" align="center" />
            </PieChart>
          </div>
        );

        provinceTable = (
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "75%" }}>Province</th>
                <th style={{ width: "25%" }}>Companies</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reportPolicyReports.provinceCount.map(
                (data, index) => {
                  return (
                    <tr>
                      <td>{data.province}</td>
                      <td>{data.count}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        );
      }

      let datePicker = (
          <div className="enrollment-date-picker d-flex" style={{color: "black"}}>
              <div className="ms-auto">
              <CustomDateRangePicker
                  fromDate={this.state.enrollmentFromDate}
                  toDate={this.state.enrollmentToDate}
                  setFromDate={this.setEnrollmentFromDate}
                  setToDate={this.setEnrollmentToDate}
              />
              </div>
          </div>
      );

        let currentContents = null;
        if (this.state.currentTab == "policy") {
            currentContents = (
                <div className="card-body">
                    <div className="db-daily">
                        {this.state.loadingPolicySummary ? (
                            <Spinner animation="border"/>
                        ) : (
                            <>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div className="dashboard-summary-header">Enrollments</div>
                                    <select className="form-select align-right"
                                            value={this.state.enrollmentDropdown}
                                            style={{maxWidth: '200px', right: 0}}
                                            onChange={(e) => this.changeEnrollmentDropdown(e.target.value)}>
                                        <option value="ALL">Show All</option>
                                        <option value="DATE">Select by Date</option>
                                    </select>
                                </div>
                                    {this.state.enrollmentDropdown === 'ALL' ? (<></>) : datePicker}
                                <div className="row mt-3">
                                    <div className="col-md-3 col-sm-12">
                                        <div className="dashboard-summary-card">
                                            <div className="dashboard-summary-value">
                                                {this.state.reportPolicySummary.totalActive}
                                            </div>
                                            <div>Active Policies</div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportPolicySummary.totalPending}
                        </div>
                        <div>Pending Enrollments</div>
                      </div>
                    </div> */}
                                    <div className="col-md-3 col-sm-12">
                                        <div className="dashboard-summary-card">
                                            <div className="dashboard-summary-value">
                                                {this.state.reportPolicySummary.totalGroup}
                                            </div>
                                            <div>Group Policies</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="dashboard-summary-card">
                                            <div className="dashboard-summary-value">
                                                {this.state.reportPolicySummary.totalBusinessOwner}
                                            </div>
                                            <div>Business Owners</div>
                                        </div>
                                    </div>
                                    {" "}
                                    <div className="col-md-3 col-sm-12">
                                        <div className="dashboard-summary-card">
                                            <div className="dashboard-summary-value">
                                                {this.state.reportPolicySummary.totalEmployees}
                                            </div>
                                            <div>Active Employees</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="db-claims">
                        {this.state.loadingPolicyReports ? (
                            <Spinner animation="border"/>
                        ) : (
                            <>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div className="dashboard-summary-header">
                                        Policies Summary
                                    </div>
                                </div>
                                <div className="row mt-3">
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {
                            this.state.reportPolicyReports.benefitTypeTotals
                              .totalHsaOnly
                          }
                        </div>
                        <div>HSA Only Policies</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {
                            this.state.reportPolicyReports.benefitTypeTotals
                              .totalLsaOnly
                          }
                        </div>
                        <div>LSA Only Policies</div>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {
                            this.state.reportPolicyReports.benefitTypeTotals
                              .totalFixed
                          }
                        </div>
                        <div>Fixed HSA/LSA Policies</div>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {
                            this.state.reportPolicyReports.benefitTypeTotals
                              .totalFlex
                          }
                        </div>
                        <div>Flex HSA/LSA Policies</div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col col-4">{provinceTable}</div>
                      <div className="col col-8">{provincePie}</div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <BarChart
                      width={800}
                      height={400}
                      data={distributionData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      {/* <Legend /> */}
                      <Bar dataKey="Companies" fill="#059454" />
                    </BarChart>
                    <div className="text-center">Employee Distribution</div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      } else if (this.state.currentTab == "advisors") {
        let largestDistros = null;
        if (
          this.state.reportAdvisors != null &&
          this.state.reportAdvisors.distroAdvisorCount.length > 0
        ) {
          largestDistros = (
              <>
              <table className="table">
                  <thead>
                  <tr>
                      <th style={{width: "75%"}} onClick={() => this.handleSort('name', this.state.reportAdvisors.distroAdvisorCount, 'distro')}>
                          Distro Account {this.state.sortColumn === 'name' ? (this.state.sortDirection === 'asc' ? '↑' : '↓') : ''}
                      </th>
                      <th style={{width: "25%"}} onClick={() => this.handleSort('count', this.state.reportAdvisors.distroAdvisorCount, 'distro')}>
                          Total Advisors {this.state.sortColumn === 'count' ? (this.state.sortDirection === 'asc' ? '↑' : '↓') : ''}
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.currentDistroAdvisorRecords().map(
                      (data, index) => {
                          return (
                              <tr key={index}>
                                  <td>{data.name}</td>
                                  <td>{data.count}</td>
                              </tr>
                          );
                      }
                  )}
                  </tbody>
              </table>
                  <div className="pagination-container" style={{float: 'inline-end'}}>
                      <div className="pagination-info">
                          <span>Showing {this.state.currentPageDistroAdvisors * this.state.distroAdvisorItemsPerPage - this.state.distroAdvisorItemsPerPage + 1} - {Math.min(this.state.currentPageDistroAdvisors * this.state.distroAdvisorItemsPerPage, this.state.reportAdvisors.distroAdvisorCount.length)} entries out of {this.state.reportAdvisors.distroAdvisorCount.length} entries</span>
                      </div>
                      <CustomPagination
                          nPages={this.nPages(this.state.reportAdvisors.distroAdvisorCount.length, this.state.advisorPolicyItemsPerPage)}
                          currentPage={this.state.currentPageDistroAdvisors}
                          setCurrentPage={this.setDistroAdvisorCurrentPage}
                      />
                  </div>
              </>
          );
        }

          let largestAdvisors = null;
          if (
              this.state.reportAdvisors != null &&
              this.state.reportAdvisors.advisorPolicyCount.length > 0
          ) {
              largestAdvisors = (
                  <>
                  <table className="table">
                  <thead>
                  <tr>
                      <th style={{ width: "75%" }} onClick={() => this.handleSort('name', this.state.reportAdvisors.advisorPolicyCount, 'policy')}>
                          Advisor Name {this.state.sortColumn === 'name' ? (this.state.sortDirection === 'asc' ? '↑' : '↓') : ''}
                      </th>
                      <th style={{ width: "25%" }} onClick={() => this.handleSort('count', this.state.reportAdvisors.advisorPolicyCount, 'policy')}>
                          Total Policies {this.state.sortColumn === 'count' ? (this.state.sortDirection === 'asc' ? '↑' : '↓') : ''}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.currentAdvisorPolicyRecords().map(
                      (data, index) => {
                        return (
                          <tr>
                            <td>{data.name}</td>
                            <td>{data.count}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <div className="pagination-container" style={{float: 'inline-end'}}>
                    <div className="pagination-info">
                        <span>Showing {this.state.currentPageAdvisorPolicy * this.state.advisorPolicyItemsPerPage - this.state.advisorPolicyItemsPerPage + 1} - {Math.min(this.state.currentPageAdvisorPolicy * this.state.advisorPolicyItemsPerPage, this.state.reportAdvisors.advisorPolicyCount.length)} entries out of {this.state.reportAdvisors.advisorPolicyCount.length} entries</span>
                    </div>
                    <CustomPagination
                        nPages={this.nPages(this.state.reportAdvisors.advisorPolicyCount.length, this.state.advisorPolicyItemsPerPage)}
                        currentPage={this.state.currentPageAdvisorPolicy}
                        setCurrentPage={this.setAdvisorPolicyCurrentPage}
                    />
                </div>
            </>
          );
        }

        currentContents = (
          <div className="card-body">
            <div className="db-daily">
              {this.state.loadingAdvisorReports ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <div className="dashboard-summary-header">Distro Totals</div>

                  <div className="row mt-3">
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportAdvisors.totalDistros}
                        </div>
                        <div>Active Distros</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportAdvisors.totalAdvisors}
                        </div>
                        <div>Active Advisors</div>
                      </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportPolicySummary.totalBusinessOwner}
                        </div>
                        <div>Business Owners</div>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportPolicySummary.totalEmployees}
                        </div>
                        <div>Active Employees</div>
                      </div>
                    </div> */}
                  </div>
                </>
              )}
            </div>
            <div className="db-claims">
              {this.state.loadingAdvisorReports ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <div className="mb-2 d-flex justify-content-between">
                    <div className="dashboard-summary-header">
                      Distros & Advisors Summary
                    </div>
                  </div>
                  <div className="row mt-3">{largestDistros}</div>
                  <div className="row mt-3">{largestAdvisors}</div>
                </>
              )}
            </div>
          </div>
        );
      } else if (this.state.currentTab === "financials") {
        
        currentContents = (
            <div>
              <OpsFinancialsReport/>
            </div>
        )
      } else {
          currentContents = (
              <div className="card-body">
                  <div className="db-daily">
                      {this.state.loadingDaily ? (
                          <Spinner animation="border"/>
                      ) : (
                          <>
                              <div className="dashboard-summary-header">Daily Summary</div>

                              <div className="row mt-3">
                              <div className="col-md-3 col-sm-12">
                                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportDaily.dailyQueued}
                        </div>
                        <div>Queued Claims</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportDaily.dailyNew}
                        </div>
                        <div>New Claims</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="dashboard-summary-card">
                        <div className="dashboard-summary-value">
                          {this.state.reportDaily.dailyPending}
                        </div>
                        <div>Pending Claims</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      {/* <div className="dashboard-summary-card">
                    <div className="dashboard-summary-value">
                      {this.state.reportDaily.dailyApproved}
                    </div>
                    <div>Approved Claims</div>
                  </div> */}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="db-claims">
              {this.state.loadingClaims ? (
                <Spinner animation="border" />
              ) : (
                  <>
                      <div className="mb-2 d-flex justify-content-between">
                          <div className="dashboard-summary-header">
                              Claim Summary
                          </div>
                          <CustomDateRangePicker
                              fromDate={this.state.fromDate}
                              toDate={this.state.toDate}
                              setFromDate={this.setFromDate}
                              setToDate={this.setToDate}
                          />
                      </div>

                      <div>
                          <label>Include Paid & Unpaid Claims</label>
                          <br/>
                          <label className="switch">
                              <input type="checkbox" name="claimsAdjudicated"
                                     onChange={(e) => this.setState({claimsAdjudicated: e.target.checked})}
                                     checked={this.state.claimsAdjudicated}/>
                              <span className="slider round"></span>
                          </label>
                      </div>
                      {this.state.claimsAdjudicated ?
                          <div className="row mt-3 justify-content-center">
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.paidAndAdjudicatedCount}
                                      </div>
                                      <div>Paid and Unpaid Total Count</div>
                                  </div>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.paidAndAdjudicatedAmount.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                          })}
                                      </div>
                                      <div>Paid and Unpaid Total Amount</div>
                                  </div>
                              </div>
                          </div>
                          :
                          <div className="row mt-3">
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.paidCount}
                                      </div>
                                      <div>Paid Total Count</div>
                                  </div>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.paidAmount.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                          })}
                                      </div>
                                      <div>Paid Total Amount</div>
                                  </div>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.unpaidCount}
                                      </div>
                                      <div>Unpaid Total Count</div>
                                  </div>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                  <div className="dashboard-summary-card">
                                      <div className="dashboard-summary-value">
                                          {this.state.reportClaims.unpaidAmount.toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                          })}
                                      </div>
                                      <div>Unpaid Total Amount</div>
                                  </div>
                              </div>
                          </div>
                      }

                      <div className="row mt-3">
                          <div className="col-md-4 col-sm-12">
                              <PieChart width={300} height={300}>
                                  <Pie
                                      data={accountTypeChart}
                                      cx="50%"
                                      cy="50%"
                                      labelLine={false}
                                      label={renderCustomizedLabel}
                                      outerRadius={120}
                                      fill="#8884d8"
                                      dataKey="value"
                                  >
                                      {accountTypeChart.map((entry, index) => (
                                          <Cell
                                              key={`cell-${index}`}
                                              fill={pieColors[index % pieColors.length]}
                                          />
                                      ))}
                                  </Pie>
                              </PieChart>
                              <div className="text-center">Paid Claims by Type</div>
                          </div>
                          {" "}
                          <div className="col-md-4 col-sm-12">
                              <PieChart width={300} height={300}>
                                  <Pie
                                      data={zeroPaidChart}
                                      cx="50%"
                                      cy="50%"
                                      labelLine={false}
                                      label={renderZeroPaidLabel}
                                      outerRadius={120}
                                      fill="#8884d8"
                                      dataKey="value"
                                  >
                                      {zeroPaidChart.map((entry, index) => (
                                          <Cell
                                              key={`cell-${index}`}
                                              fill={
                                                  zeroPaidColors[index % zeroPaidColors.length]
                                              }
                                          />
                                      ))}
                                  </Pie>
                              </PieChart>
                              <div className="text-center">
                                  Approved (Paid / $0 Paid)
                              </div>
                          </div>
                          <div className="col-md-4 col-sm-12"></div>
                      </div>

                      <div className="row mt-4">
                          <div className="col-md-12 col-sm-12">
                              <BarChart
                                  width={1000}
                                  height={400}
                                  data={adjudicationTotalsChart}
                                  margin={{
                                      top: 20,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                  }}
                              >
                                  <CartesianGrid strokeDasharray="3 3"/>
                                  <XAxis dataKey="name"/>
                                  <YAxis/>
                                  <Tooltip/>
                                  <Legend/>
                                  <Bar dataKey="totalPaid" stackId="a" fill="#059454"/>
                                  <Bar dataKey="totalAdjudicated" stackId="a" fill="blue"/>
                                  <Bar
                                      dataKey="totalZeroPaid"
                                      stackId="a"
                                      fill="#dc3545"
                                  />
                              </BarChart>
                              <div className="text-center">
                                  Adjudicated Claims (by Adjudicator)
                              </div>
                          </div>
                      </div>
                  </>
              )}
            </div>
              </div>
          );
        }

        return (
            <div style={{width: "100%", padding: 30}}>
                <div className="container">
                    <div className="card mt-3">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className={`${
                                        this.state.currentTab === "adjudication"
                                            ? "nav-link active"
                                            : "nav-link"
                                    }`}
                                    onClick={() => this.setTab("adjudication")}
                                    href="#"
                                >
                                    <h1 style={{padding: 10, fontSize: 24, fontWeight: 500}}>
                                        Claims & Adjudication
                                    </h1>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`${
                                        this.state.currentTab === "financials"
                                            ? "nav-link active"
                                            : "nav-link"
                                    }`}
                                    onClick={() => this.setTab("financials")}
                                    href="#"
                                >
                                    <h1 style={{padding: 10, fontSize: 24, fontWeight: 500}}>
                                        Financials
                                    </h1>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`${
                                        this.state.currentTab === "policy"
                                            ? "nav-link active"
                                            : "nav-link"
                                    }`}
                                    onClick={() => this.setTab("policy")}
                                    href="#"
                                >
                                    <h1 style={{padding: 10, fontSize: 24, fontWeight: 500}}>
                                        Policies & Enrollments
                                    </h1>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`${
                                        this.state.currentTab === "advisors"
                                            ? "nav-link active"
                                            : "nav-link"
                                    }`}
                                    onClick={() => this.setTab("advisors")}
                                    href="#"
                                >
                                    <h1 style={{padding: 10, fontSize: 24, fontWeight: 500}}>
                                        Advisors
                                    </h1>
                                </a>
                            </li>
                        </ul>
                        {currentContents}
            </div>
          </div>
        </div>
      );
    }
  }
);
